<template>
  <div class="container-fluid home-about-container">
    <div class="row home-about-row">
      <div class="col-12 col-lg-7 mb-4">
        <h2 class="mb-4">{{ $t('home.about') }}</h2>
        <p>{{ $t('home.about_description') }}</p>
        <p>{{ $t('home.about_description2') }}</p>
        <localized-link :to="{ name: 'About' }"
          ><app-button mode="mt-2">
            {{ $t('home.cta2') }}</app-button
          ></localized-link
        >
      </div>

      <div class="col-12 col-lg-5 mb-4 img-col">
        <img class="img-fluid" src="@/assets/img/energy.png" alt="fuel-img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutSection',
};
</script>

<style lang="scss">
.home-about-container {
  .home-about-row {
    transform: translateY(-3rem);
    max-width: 90%;
    margin: 0 auto;
    .col-lg-7 {
      padding-right: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      a {
        text-align: end;
      }
    }
    .img-col {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .img-fluid {
        width: 70%;
        margin-right: 2rem;
      }
    }
  }
}

@include bp-down(xl) {
  .home-about-container {
    .home-about-row {
      .col-lg-7 {
        text-align: center;
      }
      .img-col {
        justify-content: center;
        .img-fluid {
          width: 80%;
        }
      }
    }
  }
}

@include bp-down(lg) {
  .home-about-container {
    .home-about-row {
      .col-lg-7 {
        text-align: center;
        padding-bottom: 2rem;
        a {
          text-align: center;
        }
      }
      .img-col {
        justify-content: center;
        .img-fluid {
          width: 40%;
        }
      }
    }
  }
}

@include bp-down(md) {
  .home-about-container {
    .home-about-row {
      .img-col {
        justify-content: center;
        .img-fluid {
          width: 60%;
        }
      }
    }
  }
}
</style>
