<template>
  <nav class="navbar">
    <div class="container-fluid">
      <localized-link class="navbar-brand" to="/">
        <img src="@/assets/img/logo.png" class="logo" alt="logo" />
      </localized-link>
      <div class="nav-items">
        <LangSwitcher />
        <i class="bi bi-list" @click="drawer = true"></i>
        <el-drawer v-model="drawer" direction="rtl">
          <ul @click="drawer = false">
            <li>
              <localized-link :to="{ name: 'Home' }">{{
                $t('nav.home')
              }}</localized-link>
            </li>
            <li>
              <localized-link :to="{ name: 'About' }">{{
                $t('nav.about')
              }}</localized-link>
            </li>
            <li>
              <localized-link :to="{ name: 'Explore' }">{{
                $t('nav.explore')
              }}</localized-link>
            </li>
            <li>
              <localized-link :to="{ name: 'Evaluate' }">{{
                $t('nav.evaluate')
              }}</localized-link>
            </li>
            <li>
              <localized-link :to="{ name: 'Compare' }">{{
                $t('nav.compare')
              }}</localized-link>
            </li>
          </ul>
        </el-drawer>
      </div>
    </div>
  </nav>
</template>

<script>
import LangSwitcher from '@/components/nav/LangSwitcher';

export default {
  name: 'Navbar',
  components: {
    LangSwitcher,
  },
  data() {
    return {
      drawer: false,
    };
  },
};
</script>

<style lang="scss">
.navbar {
  .container-fluid {
    padding: 0rem 2rem !important;
    .navbar-brand {
      margin-right: 0 !important;
      .logo {
        height: 80px;
      }
    }
    .nav-items {
      display: flex;
      align-items: center;
      justify-content: center;

      .bi {
        font-size: 2.5rem;
        cursor: pointer;
        color: $dark;
        margin-left: 1.5rem;
        transform: translateY(2px);
      }
      ul {
        li {
          list-style: none;
          margin-bottom: 1rem;
          a {
            text-decoration: none;
            color: $dark;
            font-size: 1.5rem;
            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }
}

@include bp-up(md) {
  .navbar {
    .container-fluid {
      padding: 0rem 0.25rem;
      .nav-item {
        display: block;
      }
    }
  }
}

@include bp-down(lg) {
  .el-drawer {
    width: 60% !important;
  }
}

@include bp-down(md) {
  .el-drawer {
    width: 90% !important;
  }
}
</style>
