import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import GlobalComponents from './utils/globals';
import ElementPlus from 'element-plus';
import VueCookies from 'vue3-cookies';
import VueApexCharts from 'vue3-apexcharts';
import './utils/styles';
import { i18n } from 'vue-lang-router';
import './registerServiceWorker';

const app = createApp(App);
app
  .use(store)
  .use(router)
  .use(i18n)
  .use(GlobalComponents)
  .use(ElementPlus)
  .use(VueCookies)
  .use(VueApexCharts)
  .mount('#app');
