<template>
  <footer class="container-fluid">
    <div class="row upper-section">
      <div class="col-12 col-lg-6">
        <h6>Fuel Compass</h6>
                <p>
          <router-link :to="{ name: 'About' }">{{
            $t('nav.about')
          }}</router-link>
        </p>
        <p>
          <router-link :to="{ name: 'Explore' }">{{
            $t('nav.explore')
          }}</router-link>
        </p>
        <p>
          <router-link :to="{ name: 'Evaluate' }">{{
            $t('nav.evaluate')
          }}</router-link>
        </p>
        <p class="mb-2">
          <router-link :to="{ name: 'Compare' }">{{
            $t('nav.compare')
          }}</router-link>
        </p>
      </div>
      <div class="col-12 col-lg-6">
        <h6>WeLOOP</h6>
        <p>
          <a href="https://weloop.org" target="_blank">{{
            $t('nav.about_weloop')
          }}</a>
        </p>
        <p>
          <a href="mailto:info@weloop.org" target="_blank">{{
            $t('nav.contact')
          }}</a>
        </p>
        <p class="text-bold">{{ $t('nav.follow') }}</p>
        <div class="social-icons">
          <a
            class="bi bi-linkedin"
            href="https://www.linkedin.com/company/weloop"
            target="_blank"
          ></a>
          <a
            class="bi bi-twitter"
            href="https://twitter.com/weloop_"
            target="_blank"
          ></a>
          <a
            class="bi bi-youtube"
            href="https://www.youtube.com/channel/UC1dyvTC2eHhPtmyhVoSNMkQ"
            target="_blank"
          ></a>
        </div>
      </div>
    </div>
    <div class="row lower-section">
      <p class="footer-small">
        WeLOOP S.A.R.L. - Rue du Bourg 254, 59130 Lambersart - info@weloop.org -
        T.V.A.: FR 90830184420
      </p>
      <p class="footer-small">
        &copy; Copyright {{ year }} |
        <a href="https://weloop.org" target="_blank">WeLOOP</a> -
        {{ $t('nav.copyright') }}
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss">
footer {

  text-align: center;
  p {
    font-size: 16px;
    margin-bottom: 0;
    a {
      text-decoration: none;
      color: $dark;
      &:hover {
        color: $primary;
      }
    }
  }
  .lower-section {
    padding-top: 1rem;
    .footer-small {
      font-size: 14px;
    }
  }
  .upper-section {
    padding: 1rem 0rem;
    background-color: $light-grey;
    .social-icons {
      a {
        font-size: 25px;
        &:not(:last-child) {
          margin-right: 1rem;
        }
        &.bi-twitter {
          color: #1da1f2;
        }
        &.bi-youtube {
          color:#ff0000;
        }
        &.bi-linkedin {
          color: #0a66c2;
        }
      }
    }
  }
}
</style>
