<template>
  <language-switcher v-slot="{ links }" active-class="router-link-exact-active">
    <router-link
      class="lang-link"
      :to="link.url"
      v-for="link in links"
      :key="link.langIndex"
      :class="link.activeClass"
      exact-active-class=""
      :aria-label="link.langName"
    >
      <img
        :src="require('@/assets/img/fr.png')"
        alt="fr-flag"
        v-if="link.langName === 'FR'"
      />
      <img
        :src="require('@/assets/img/en.png')"
        alt="en-flag"
        v-if="link.langName === 'EN'"
      />
    </router-link>
  </language-switcher>
</template>

<script>
export default {
  name: 'LangSwitcher',
};
</script>

<style lang="scss">
.router-language-switcher {
  .lang-link {
    display: inline-block;
    color: $dark;
    transition: all 0.3s ease;
    img {
      height: 20px;
      width: 30px;
    }
  }
  .lang-link:nth-child(1) {
    padding-right: 0.5rem;
    border: solid;
    border-width: 0px 2px 0px 0px;
    border-color: $light-grey;
  }
  .lang-link:nth-child(2) {
    padding-left: 0.5rem;
  }
}
</style>
