<template>
  <el-progress type="dashboard" :percentage="score" :color="manageColor" />
</template>

<script>
export default {
  name: 'AppDashScore',
  props: ['stars', 'score'],
  methods: {
    manageColor() {
      if (this.stars === 0 || this.stars === 1) {
        return '#de3700';
      }

      if (this.stars === 2) {
        return '#f58b00';
      }

      if (this.stars === 3) {
        return '#ffd500';
      }

      if (this.stars === 4) {
        return '#92e000';
      }

      if (this.stars === 5) {
        return '#2aa10f';
      }
    },
  },
};
</script>
