<template>
  <button
    class="btn shadow"
    :class="mode"
    @click="btnClicked"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ['mode', 'disabled'],
  emits: ['clicked'],
  methods: {
    btnClicked() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss">
.btn {
  background-color: $light;
  border-color: $primary;
  border-radius: 0;
  padding: 10px 25px;
  text-align: center;
  font-family: $default-font;
  font-size: 20px;
  color: $light;
  background-color: $primary;
  &:hover {
    background-color: $secondary;
    color: $light;
    border-color: $secondary;
  }
  a {
    color: $light;
    text-decoration: none;
  }
  &.small-fz {
    font-size: 16px;
  }
}
</style>
