<template>
  <header-section />
  <about-section />
</template>

<script>
import HeaderSection from '@/components/home/HeaderSection';
import AboutSection from '@/components/home/AboutSection';

export default {
  name: 'Home',
  components: {
    AboutSection,
    HeaderSection,
  },
};
</script>
