<template>
  <div class="progress">
    <div
      class="progress-bar"
      role="progressbar"
      :class="`score-${stars}`"
      :style="`width: ${score}%;`"
      :aria-valuenow="score"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'AppIndicator',
  props: ['indicator'],
  data() {
    return {
      score: null,
      stars: '',
    };
  },
  created() {
    if (this.indicator === 100) {
      this.score = 1;
      this.stars = 5;
    } else {
      this.score = 100 - this.indicator;
      this.stars = Math.round(this.indicator / 20);
    }
  },
};
</script>

<style lang="scss">
.progress {
  max-width: 70%;
  height: 10px;
  margin-bottom: 0.5rem;
}
</style>
