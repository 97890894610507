<template>
  <div class="rating-wrapper">
    <i
      class="bi bi-star-fill"
      v-for="(star, index) in filledStars"
      :key="index"
      :style="[
        filledStars === 5 || filledStars === 4 ? 'color: #2AA10F' : '',
        filledStars === 2 ? 'color: #f58b00' : '',
        filledStars === 3 ? 'color: #ffd500' : '',
      ]"
    ></i>

    <i class="bi bi-star" v-for="(star, index) in stars" :key="index"></i>
  </div>
</template>

<script>
export default {
  name: 'AppRating',
  props: ['value'],
  data() {
    return {
      stars: 5,
      filledStars: 0,
    };
  },
  created() {
    const rating = this.value / 20;
    const starsNumber = Math.round(rating);
    this.filledStars = starsNumber;
    this.stars = 5 - starsNumber;

    if (this.stars === 5) {
      this.stars = 4;
      this.filledStars = 1;
    }
  },
};
</script>

<style lang="scss" scoped>
.rating-wrapper {
  margin-left: 1rem;
  i {
    font-size: 18px;
    margin-right: 0.3rem;
  }
  .bi-star:nth-child(1),
  .bi-star-fill:nth-child(1) {
    color: #de3700;
  }

  .bi-star:nth-child(2),
  .bi-star-fill:nth-child(2) {
    color: #f58b00;
  }

  .bi-star:nth-child(3),
  .bi-star-fill:nth-child(3) {
    color: #ffd500;
  }

  .bi-star:nth-child(4),
  .bi-star-fill:nth-child(4) {
    color: #92e000;
  }

  .bi-star:nth-child(5),
  .bi-star-fill:nth-child(5) {
    color: #2aa10f;
  }
}

@include bp-down(md) {
  .rating-wrapper {
    display: none;
  }
}
</style>
