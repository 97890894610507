import { createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import translations from '../lang/translations';
import localizedURLs from '../lang/localized-urls';
import { createLangRouter } from 'vue-lang-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "discover" */ '../views/About.vue'),
  },
  {
    path: '/evaluate',
    name: 'Evaluate',
    component: () =>
      import(/* webpackChunkName: "evaluate" */ '../views/Evaluate.vue'),
  },
  {
    path: '/compare',
    name: 'Compare',
    component: () =>
      import(/* webpackChunkName: "compare" */ '../views/Compare.vue'),
  },
  {
    path: '/explore',
    name: 'Explore',
    component: () =>
      import(/* webpackChunkName: "explore" */ '../views/Explore.vue'),
  },
  {
    path: '/evaluate/test',
    name: 'Survey',
    component: () =>
      import(/* webpackChunkName: "compare" */ '../components/evaluate/Survey.vue'),
  },
  {
    path: '/results/:id',
    name: 'Results',
    component: () =>
      import(/* webpackChunkName: "results" */ '../views/Results.vue'),
  },
  {
    path: '/:catchAll(.*)*',
    redirect: { name: 'Home' },
  },
];

const langRouterOptions = {
  defaultLanguage: 'en',
  translations,
  localizedURLs,
};
const routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes,
};
const router = createLangRouter(langRouterOptions, routerOptions);

export default router;
