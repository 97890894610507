<template>
  <div class="row home-cards-row">
    <div class="col-12 col-lg-4">
      <localized-link :to="{ name: 'Explore' }" class="card-link">
        <div class="card shadow">
          <i class="bi bi-search"></i>
          <h2>{{ $t('nav.discover') }}</h2>
          <p>
            {{ $t('home.discover') }}
          </p>
        </div>
      </localized-link>
    </div>
    <div class="col-12 col-lg-4">
      <localized-link :to="{ name: 'Evaluate' }" class="card-link">
        <div class="card shadow">
          <i class="bi bi-pencil-square"></i>
          <h2>{{ $t('nav.evaluate') }}</h2>
          <p>
            {{ $t('home.evaluate') }}
          </p>
        </div>
      </localized-link>
    </div>
    <div class="col-12 col-lg-4">
      <localized-link :to="{ name: 'Compare' }" class="card-link">
        <div class="card shadow">
          <i class="bi bi-bar-chart-line"></i>
          <h2>{{ $t('nav.compare') }}</h2>
          <p>
            {{ $t('home.compare') }}
          </p>
        </div>
      </localized-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardsRow',
};
</script>

<style lang="scss">
.home-cards-row {
  transform: translateY(-5rem);
  max-width: 90%;
  margin: 0 auto;
  .col-12 {
    .card-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .card {
        h2 {
          display: none;
        }
        i {
          font-size: 3rem;
          color: $primary;
          transition: all 0.2s ease-in-out;
        }
        p {
          margin: 0;
        }
      }
      &:hover {
        .card {
          i {
            color: $secondary;
          }
        }
      }
    }
  }
}
</style>
