<template>
  <div class="see-link">
    <localized-link :to="{ name: link }">
      {{ $t(`nav.${link.toLowerCase()}`) }}
      <i class="bi bi-arrow-right"></i>
    </localized-link>
  </div>
</template>

<script>
export default {
  name: 'SeeLink',
  props: ['link'],
};
</script>

<style lang="scss">
.see-link {
    display: flex;
    align-items: center;
}
</style>
