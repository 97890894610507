<template>
  <div class="home-banner-row row">
    <div class="col-12">
      <div class="banner-text">
        <h1>{{ $t('home.title') }}</h1>
        <p>{{ $t('home.by_weloop') }}</p>
        <h2 class="home-h2">
          {{ $t('home.description') }}
        </h2>
        <localized-link :to="{ name: 'About' }"
          ><app-button mode="mt-4">
            {{ $t('home.cta') }}</app-button
          ></localized-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerRow',
};
</script>

<style lang="scss">
.home-banner-row {
  background: $bg-color;
  min-height: 55vh !important;
  min-height: 100%;
  padding: 0rem 2rem;
  .col-12 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    text-align: center;
    .banner-text {
      padding-bottom: 8rem;
      h1 {
        color: $primary;
        margin-bottom: 0;
      }
    }
  }
}
</style>
