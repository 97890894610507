<template>
  <div class="container-fluid home-container">
    <banner-row />
    <cards-row />
  </div>
</template>

<script>
import BannerRow from '@/components/home/BannerRow';
import CardsRow from '@/components/home/CardsRow';

export default {
  name: 'HeaderSection',
  components: {
    CardsRow,
    BannerRow,
  },
};
</script>

<style lang="scss">
.home-container {
  display: flex;
  justify-content: center;
  padding: 0 !important;
  flex-direction: column;
  align-items: center;
}
</style>
