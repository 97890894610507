<template>
  <el-tooltip :content="info" placement="bottom" effect="light">
    <i class="bi bi-question-square"></i>
  </el-tooltip>
</template>

<script>
import { ElTooltip } from 'element-plus';
export default {
  name: 'AppTooltip',
  components: {
    ElTooltip,
  },
  props: ['info'],
};
</script>
