<template>
  <Navbar />
  <main><router-view /></main>
  <AppFooter />
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import Navbar from '@/components/nav/Navbar';
import AppFooter from '@/components/nav/Footer';
export default {
  name: 'App',
  components: {
    Navbar,
    AppFooter,
  },
  created() {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      localStorage.setItem('userId', uuidv4());
    }
  },
};
</script>
<style lang="scss">
main {
  min-height: 80vh !important;
}
</style>
